import initialstate from '../initialstate'
import { planActionConstants } from '../planActionConstants'

export default function webinarPlanReduder(state = initialstate, action) {
    switch (action.type) {
        case planActionConstants.USER_WEBINAR_PLAN_LOADING:
            return {
                ...state,
                user_webinar_plan_loading: true
            }
        case planActionConstants.USER_WEBINAR_PLAN_LOADING_SUCCESS:
            return {
                ...state,
                user_webinar_plan_loading: false,
                user_webinar_plan_list: action.payload
            }
        case planActionConstants.USER_WEBINAR_PLAN_WEBINAR_LIST_LOADING:
            return {
                ...state,
                user_webinar_plan_webinar_list_loading: true
            }
        case planActionConstants.USER_WEBINAR_PLAN_WEBINAR_LIST_COMPLETE:
            return {
                ...state,
                user_webinar_plan_webinar_list_loading: false,
                user_webinar_plan_webinar_list: action.payload
            }
        case planActionConstants.USER_WEBINAR_PLAN_WEBINAR_LIST_LOADING_NEXT_PAGE:
            return {
                ...state,
                user_webinar_plan_webinar_list_next_page_loading: true
            }
        case planActionConstants.USER_WEBINAR_PLAN_WEBINAR_LIST_COMPLETE_NEXT_PAGE:
            let new_data = action.payload
            let list_w = [...new_data.data]
            let list_data = state.user_webinar_plan_webinar_list
            let new_state = []
            new_state.push({
                data: [...list_data.data, ...list_w],
                nextpage: new_data.nextpage,
                paging: new_data.paging,
            })
            return {
                ...state,
                user_webinar_plan_webinar_list_next_page_loading: false,
                user_webinar_plan_webinar_list: new_state[0]
            }
        case planActionConstants.ADD_WEBINAR_TO_PLAN_LOADING:
            new_data = action.payload
            list_data = state.user_webinar_plan_webinar_list
            let w_data = list_data.data.splice(new_data.index, 1)
            w_data[0].getting_added = true
            list_data.data.splice(new_data.index, 0, w_data[0])
            return {
                ...state,
                user_webinar_plan_webinar_list: list_data
            }
        case planActionConstants.ADD_WEBINAR_TO_PLAN_LOADING_DONE:
            new_data = action.payload
            list_data = state.user_webinar_plan_webinar_list
            w_data = list_data.data.splice(new_data.index, 1)
            w_data[0].getting_added = false
            w_data[0].is_added = true
            list_data.data.splice(new_data.index, 0, w_data[0])
            return {
                ...state,
                user_webinar_plan_webinar_list: list_data
            }
        default:
            return {
                ...state
            }
    }
}