const initialstate = {
    course_data: [],
    my_course_data: [],
    my_webinar_data: [],
    my_upcoming_webinar_data: [],
    my_webinar_paging: {},
    course_initial_data_loading: true,
    loading: true,
    pageloading: false,
    course_unit_loading: false,
    section_selected: 0,
    chapter_selected: 0,
    unit_selected: 0,
    unit_data: [],
    unit_completed_data: [],
    course_completion_percentage_info:[],
    course_test_loading: true,
    course_test_data: [],
    course_test_data_success_value:null,
    course_test_all_data: null,
    course_test_started: false,
    rating_data: [],
    rating_data_loading: true,
    all_rating_data:[],
    all_rating_data_paging:{},
    avarage_rating_data_loading:false,
    avarage_rating_data:{},
    my_rating_review:[],
    user_rating_loading:false,
    user_rating_paging_loading:false,
    current_question_no: 0,
    next_question_loading: false,
    user_not_logged_in: false,
    course_test_submit: false,
    course_test_result_loading: false,
    course_test_result: [],
    course_discussion_loading: true,
    course_discussion: [],
    course_discussion_next_loading: false,
    course_discussion_post_question_loading: false,
    course_discussion_post_question_success: false,
    course_discussion_save_vote_loading: false,
    course_discussion_save_vote_success: true,
    user_webinar_plan_loading: true,
    user_webinar_plan_list:[],
    user_webinar_plan_webinar_list_loading: true,
    user_webinar_plan_webinar_list:[],
    user_webinar_plan_webinar_list_next_page_loading: false,
    user_course_plan_list:[],
    user_course_plan_course_list_loading: true,
    user_course_plan_course_list:[],
    user_course_plan_course_list_next_page_loading: false,
    user_data_loading: true,
    user_data: [],
    user_all_data: [],
    user_all_data_loading: true,
    course_batch_loading: true,
    course_batch_data: [],
    course_batch_loaded: false,
    session_video_set:[],
    selected_course_unit: [],
    like_loading:false
}



export default initialstate;