import React, { useEffect, useState } from 'react'
import Layout from './Layout';
import { useDispatch, useSelector } from 'react-redux'
import UserCourse from "./Element/UserCourse";
import { myaccountAction } from './redux/actions/myaccountAction'
import { webinarPlanAction } from "./redux/actions/webinarPlanAction";
import ErrorModal from './Element/ErrorModal'
import Loader from "./Element/Loader"
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const MyCourses = (props) => {

  const myaccount = useSelector(state => state.myaccount)

  const dispatch = useDispatch()
  const [currentCourse, setCurrentCourse] = useState({})
  const [show, setShow] = useState(false)
  const plansData = useSelector((state) => state.webinarplan);
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    getUserCourse()
    getWebinarPlan()
  }, [])

  const getUserCourse = async () => {
    try {
      await dispatch(
        myaccountAction.getMyCourses()
      );
    } catch (err) {
      setIsError(true)
      throw new Error(err)
    }
  }
  const handleCurrentCourse = () => {
    return currentCourse;
  }
  const getShow = () => {
    return show;
  }

  const getWebinarPlan = () => {
      try {
        dispatch(webinarPlanAction.getUserPlansDetails());
      } catch (error) {
        alert("error");
      }
    };


  return (

    <Layout nameprefix="My" name="Courses">
      {!plansData.user_webinar_plan_loading &&
          plansData.user_webinar_plan_list.currentsubscription != null && (
            <div className="col-lg-12 mb-4">
              <div className="section-header">
                <p>
                  Plans and <span className="blue-text">Subscription</span>
                </p>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <table className="webinar-plan-table table-responsive">
                    <tr>
                      <th>Plan Name</th>
                      <th>Expiry Date</th>
                      <th>Used</th>
                      <th>Available</th>
                      <th></th>
                    </tr>
                    <tr>
                      <td>
                        <span className="plan-name">
                          <img
                            className="mr-1"
                            src={
                              plansData.user_webinar_plan_list
                                .currentsubscription != null
                                ? plansData.user_webinar_plan_list
                                    .currentsubscription.subscription_plan
                                    .plan_image
                                : ""
                            }
                            style={{ width: "20px" }}
                          />
                          {plansData.user_webinar_plan_list
                            .currentsubscription != null
                            ? plansData.user_webinar_plan_list
                                .currentsubscription.subscription_plan.name
                            : ""}
                        </span>
                      </td>
                      <td>
                        {
                          plansData.user_webinar_plan_list.currentsubscription
                            .expiry_date
                        }
                      </td>
                      <td>
                        {
                          plansData.user_webinar_plan_list.currentsubscription
                            .no_purchased_courses
                        }
                      </td>
                      <td>
                        {
                          plansData.user_webinar_plan_list.currentsubscription
                          .is_available_all_course ? <>Unlimited</> :
                          plansData.user_webinar_plan_list.currentsubscription
                            .no_available_courses
                        }
                      </td>
                      <td className="text-center">
                        {plansData.user_webinar_plan_list.currentsubscription
                          .expiry_diff > -1 && (
                          <Link
                            to={`/course-plan/`}
                            className="view-plan-btn"
                            style={{ color: "white" }}
                          >
                            Add Courses
                          </Link>
                        )}
                        {plansData.user_webinar_plan_list.currentsubscription
                          .is_renew && (
                          <a
                            className="view-plan-btn"
                            style={{
                              background: "#ee9949",
                              color: "#fff",
                              border: "none",
                              fontSize: ".8rem",
                              marginLeft: "5px",
                            }}
                            href={
                              process.env.REACT_APP_NEXT_HOME_URL +
                              "webinar-plans"
                            }
                            target="_blank"
                          >
                            Renew
                          </a>
                        )}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          )}
      {
        !myaccount.loading && !isError &&
        <UserCourse
          feedData={myaccount.my_course_data}
          name={``}
          setcurrentCourse={setCurrentCourse}
          getcurrentCourse={handleCurrentCourse}
          getShow={getShow}
          setShow={setShow}
          loader={false}
        />
      }
      {myaccount.loading && !isError &&
        <Loader
          is_loading={myaccount.loading && !isError}
        />

      }
      {
        isError &&
        <ErrorModal
          error={isError}
        />
      }
    </Layout>

  )
}

export default MyCourses;
