import React, { Suspense, lazy } from 'react';

import './style.css';
import {
  BrowserRouter as Router, Switch, Route, Redirect
} from 'react-router-dom'

import MyCourses from './MyCourses';
import MyWebinars from './MyWebinars'
import Learn from './Learn'
import AuthorLms from './AuthorLms'

import Login from './Login';

import { Provider } from 'react-redux'
import configueStore from './redux/store'

import { NotFound } from './components/NotFound';

import withClearCache from "./ClearCache";

const Meeting = lazy(() => import('./Meeting'))
const MyCertificates = lazy(() => import('./MyCertificate'))
const UserProfile = lazy(() => import('./components/UserProfile'))
const Settings = lazy(() => import('./components/Settings'))
const Profile = lazy(() => import('./components/Profile'))
const AuthorProfile = lazy(() => import('./components/AuthorProfile'))
const HostedCourses = lazy(() => import('./components/HostedCourses'))
const EditCourseDetails = lazy(() => import('./components/EditCourseDetails'))
const AddCourseDetails = lazy(() => import('./components/AddCourseDetails'))
const CourseDesign = lazy(() => import('./components/CourseDesign'))
const Support = lazy(() => import('./components/Support'))
const WebinarPlans = lazy(() => import('./components/WebinarPlans'))
const CoursePlans = lazy(() => import('./components/CoursePlans'))
const EditProfile = lazy(() => import('./components/EditProfile'))
const Affiliates = lazy(() => import('./components/Affiliates'))
const AuthorLink = lazy(() => import('./components/AuthorLink'))
const HostedWebinars = lazy(() => import('./components/HostedWebinars'))
const HostedWebinarsCreate = lazy(() => import('./components/HostedWebinarsCreate'))
const Dashboard = lazy(() => import('./components/Dashboard'))
const CourseBatch = lazy(() => import('./components/CourseBatch'))
const AuthorReport = lazy(() => import('./Element/AuthorReport'))
const CourseDiscussion = lazy(() => import('./components/CourseDiscussion'))
const ElmSchool = lazy(() => import('./components/ElmSchool'))
const MyWorkshop = lazy(() => import('./components/MyWorkshop'))
const MyOffers = lazy(() => import('./components/MyOffers'))

const VerifyLoginOtp = lazy(()=>import ('./components/VerifyLoginOtp'))


const ClearCacheComponent = withClearCache(MainApp);

const store = configueStore()
const App = () => {
  return <ClearCacheComponent />;
}
function MainApp() {
  return (
    <Provider store={store}>
      <Router>
        <Suspense fallback={<div></div>}>

          <Switch>
            <Route exact path="/" >
              <Redirect to="/my-courses" />
            </Route>
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/my-courses" component={MyCourses} />
            <Route exact path="/my-webinars" component={MyWebinars} />
            <Route exact path="/my-certificates" component={MyCertificates} />
            <Route exact path="/elm-school" component={ElmSchool} />
            <Route exact path="/my-events" component={MyWorkshop} />
            <Route exact path="/my-offers" component={MyOffers} />
            <Route path="/learn/:id" component={Learn} />
            <Route path="/meeting/:id" component={Meeting} />
            <Route path="/login/:id" component={Login} />
            <Route exact path="/login" component={Login} />
            <Route path='/user-profile' component={UserProfile} />
            <Route path='/webinar-plan' component={WebinarPlans} />
            <Route path='/course-plan' component={CoursePlans} />
            <Route path='/hosted-courses' component={HostedCourses} />
            <Route path='/hosted-webinars' component={HostedWebinars} />
            <Route path='/hosted-webinars-create' component={HostedWebinarsCreate} />
            <Route path="/course-design/:slug/:id" component={CourseDesign} />
            <Route path="/course-detail-edit/:slug/:id/:openStep" component={EditCourseDetails} />
            <Route path="/add-course/" component={AddCourseDetails} />
            <Route path="/course-batch/:slug/:id" component={CourseBatch} />
            <Route path='/edit-profile' component={EditProfile} />
            <Route path='/support' component={Support} />
            <Route path='/settings' component={Settings} />
            <Route path='/affiliate' component={Affiliates} />
            <Route path = '/author-link' component={AuthorLink} />
            <Route path='/profile' component={Profile} />
            <Route path='/author-profile' component = {AuthorProfile}/>
            <Route path="/report/:itemId/:typeId" component = {AuthorReport} />
            <Route path = "/report" component = {AuthorReport} />
            <Route path="/discussion" component = {CourseDiscussion} />
            <Route path="/author-lms/:id" component={AuthorLms} />
            <Route path='/otpscreen' component={VerifyLoginOtp} />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Router>
    </Provider>

  )
}

export default App;
