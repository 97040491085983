import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Scrollbar } from 'react-scrollbars-custom'
import ReactTooltip from 'react-tooltip';
import completeCourse from '../complete-course.png';
import Progress from 'react-progressbar';

const AuthorLmsCourseContent = (props) => {
  useEffect(() => {
    ReactTooltip.rebuild()
  })

  return (
    <div className={`right-section-for-elearn ${props.mobilePanelClass}`}>
      <div className="side-menu-for-lms" >
        <Scrollbar>
          <p className="top-header-lms-menu" style={{ fontSize: '12px' }}>
            <span className="top-header-lms-menu-tabs" onClick={() =>props.setshowCourseContentRight(true)} style={{ backgroundColor: '#EE9949', color: '#fff' }}>Course Content</span> <a onClick={props.onclose} className="close-btn-for-lms-menu"><i className="fa fa-times" aria-hidden="true"></i></a>
            {
             /**  props.course_info.has_batch &&
              <span className="top-header-lms-menu-tabs" style={{ cursor: 'pointer', color: '#fc495c' }} onClick={() => props.showLiveClass()}>Live Sessions</span>*/
            }
          </p>
          {
            props.course_info && props.course_info.course_data.map((item, index) => (
              <div key={item.id}>
                <div className={item.showChapter ? "course-content-list-active" : "course-content-list"} onClick={() => props.opensection(index)}>
                  {
                    item.section_completion_percentage.totalPercentage >= 100 && 
                    <img alt="" src={completeCourse} className="complete-img" />
                  }
                  <span>Section {index + 1}: {item.name}</span>
                  <span className="pl-3 pull-right side-menu-navigation"><i className={item.showChapter ? "icon-small fa fa-chevron-up" : "icon-small fa fa-chevron-down"}></i></span>
                  {
                    item.section_completion_percentage.totalPercentage < 100 && item.section_completion_percentage.totalPercentage > 0 &&
                    <div className="course-progress-box">
                      <Progress completed={item.section_completion_percentage.totalPercentage} className="course-progress" /> <span className="progress-percentage">{item.section_completion_percentage.totalPercentage}%</span>
                    </div>
                  }
                  {
                    item.section_completion_percentage.totalPercentage >= 100 &&
                    <div className="small-text-under-section">Complete!</div>
                  }
                  {
                    item.section_completion_percentage.totalPercentage < 100 && item.section_completion_percentage.totalPercentage > 0 &&
                    <div className="small-text-under-section if-not-complete"> {item.section_completion_percentage.totalUnit - item.section_completion_percentage.totalUnitLearn} units left</div>
                  }
                  {
                    item.section_completion_percentage.totalPercentage < 100 && item.section_completion_percentage.totalPercentage <= 0 &&
                    <div className="small-text-under-section if-not-complete"> Total units: {item.section_completion_percentage.totalUnit}</div>
                  }
                </div>
                {
                  item.course_chapter_versions.map((chapter, ci) => (
                    <div key={ci} className="chapter-block" style={!item.showChapter ? { display: 'none' } : { display: 'block' }}>
                      <div className="chapter-box" onClick={() => props.openchapter(index, chapter.id, ci, chapter.is_unit_loaded)}>
                        <span className="chapter-name">Chapter {ci + 1}: {chapter.name}</span>
                        <span className="pull-right chapter-collapse" ><i className={props.course_info.course_unit_loading && props.course_info.loading_chapter_id === chapter.id ? 'icon-small fa fa-circle-o-notch fa-spin' : chapter.showUnit ? "icon-small fa fa-minus" : "icon-small fa fa-plus"}></i></span>
                      </div>
                      {
                        chapter.showUnit && (
                          chapter.unit.map((unit, uindex) => (
                            <Link key={uindex} to={`/author-lms/${props.course_id}/section/${item.id}/${chapter.id}/${unit.id}`}>
                              <div >
                                <div className={parseInt(props.course_info.unit_selected) === parseInt(unit.id) ? "unit-name unit-name-active" : "unit-name"}>
                                  {
                                    (unit.filecontent.file_type.id !== 7 || unit.filecontent.file_type.id !== 13) && (
                                      <span
                                        data-tip={(unit.hasOwnProperty('is_completed') && unit.is_completed === true) ? "Unit completed" : "Learn pending"}
                                        className="check-box-chapter"
                                      >
                                        <input type="checkbox" id={unit.id} checked={(unit.hasOwnProperty('is_completed') && (unit.is_completed === true))} />
                                        <span></span>
                                      </span>
                                    )
                                  }
                                  {
                                    (unit.filecontent.file_type.id === 7 || unit.filecontent.file_type.id === 13) && (
                                      <span
                                        data-tip={unit.hasOwnProperty('is_completed') && (unit.is_completed === true) ? "Unit completed" : "Complete the test"}
                                        className="check-box-chapter">
                                        <input type="checkbox" id={unit.id} checked={unit.hasOwnProperty('is_completed') && (unit.is_completed === true)} />
                                        <span></span>
                                      </span>
                                    )
                                  }

                                  <div onClick={() => props.setselectiondata(item.id, chapter.id, unit.id)} className="unit-name-box">
                                    <i className={`fa ${unit.filecontent.file_type.icon}`} aria-hidden="true"></i><span className="unit-name-text">{uindex + 1}. {unit.name}</span>
                                    {unit.filecontent.video_time &&
                                      <span className="video-time"><i class="fa fa-clock-o" aria-hidden="true"></i> {unit.filecontent.video_time}</span>
                                    }
                                  </div>
                                </div>
                              </div>
                            </Link>
                          ))
                        )
                      }
                    </div>
                  ))
                }
              </div>
            ))
          }
          <ReactTooltip className="mytooltip" />

        </Scrollbar>
      </div>
    </div>
  )
}

export default React.memo(AuthorLmsCourseContent)
