export const planActionConstants = {
    USER_WEBINAR_PLAN_LOADING: 'USER_WEBINAR_PLAN_LOADING',
    USER_WEBINAR_PLAN_LOADING_SUCCESS: 'USER_WEBINAR_PLAN_LOADING_SUCCESS',
    USER_WEBINAR_PLAN_WEBINAR_LIST_LOADING: 'USER_WEBINAR_PLAN_WEBINAR_LIST_LOADING',
    USER_WEBINAR_PLAN_WEBINAR_LIST_COMPLETE: 'USER_WEBINAR_PLAN_WEBINAR_LIST_LOADING_COMPLETE',
    USER_WEBINAR_PLAN_WEBINAR_LIST_LOADING_NEXT_PAGE: 'USER_WEBINAR_PLAN_WEBINAR_LIST_LOADING_NEXT_PAGE',
    USER_WEBINAR_PLAN_WEBINAR_LIST_COMPLETE_NEXT_PAGE: 'USER_WEBINAR_PLAN_WEBINAR_LIST_LOADING_COMPLETE_NEXT_PAGE',
    ADD_WEBINAR_TO_PLAN_LOADING: 'ADD_WEBINAR_TO_PLAN_LOADING',
    ADD_WEBINAR_TO_PLAN_LOADING_DONE: 'ADD_WEBINAR_TO_PLAN_LOADING_DONE',
    USER_COURSE_PLAN_LOADING: 'USER_COURSE_PLAN_LOADING',
    USER_COURSE_PLAN_LOADING_SUCCESS: 'USER_COURSE_PLAN_LOADING_SUCCESS',
    USER_COURSE_PLAN_COURSE_LIST_LOADING: 'USER_COURSE_PLAN_COURSE_LIST_LOADING',
    USER_COURSE_PLAN_COURSE_LIST_COMPLETE: 'USER_COURSE_PLAN_COURSE_LIST_LOADING_COMPLETE',
    USER_COURSE_PLAN_COURSE_LIST_LOADING_NEXT_PAGE: 'USER_COURSE_PLAN_COURSE_LIST_LOADING_NEXT_PAGE',
    USER_COURSE_PLAN_COURSE_LIST_COMPLETE_NEXT_PAGE: 'USER_COURSE_PLAN_COURSE_LIST_LOADING_COMPLETE_NEXT_PAGE',
    ADD_COURSE_TO_PLAN_LOADING: 'ADD_COURSE_TO_PLAN_LOADING',
    ADD_COURSE_TO_PLAN_LOADING_DONE: 'ADD_COURSE_TO_PLAN_LOADING_DONE'
}